<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          <span>
            Grupos
          </span>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            outlined
            @click="modal = true, clearModal()"
          >
            <v-icon class="mr-1">
              {{ icons.mdiPlaylistPlus }}
            </v-icon>
            Novo
          </v-btn>
        </v-card-title>

        <v-row>
          <v-col
            cols="12"
          >
            <v-data-table
              :loading="loadTable"
              loading-text="Carregando dados... Por favor, aguarde"
              :headers="headers"
              :items="items"
              :items-per-page="10"
            >
              <template #[`item.cnpj`]="{item}">
                {{ formattedCnpjNumber(item.cnpj) }}
              </template>

              <template #[`item.telephone`]="{item}">
                {{ formattedPhoneNumberToBrazil(item.telephone) }}
              </template>

              <template #[`item.edit`]="{ item }">
                <v-icon
                  color="info"
                  @click="getGroupInfo(item)"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
              </template>

              <template #[`item.delete`]="{ item }">
                <v-icon
                  color="error"
                  @click="deleteGroup(item)"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-dialog
          v-model="modal"
          width="600"
        >
          <v-card>
            <v-card-title
              color="error"
            >
              <h3>Grupos</h3>
            </v-card-title>
            <v-card>
              <v-card-text>
                <form action="">
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="group.corporate_name"
                        dense
                        outlined
                        label="Nome Corporativo"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="group.fantasy_name"
                        dense
                        outlined
                        label="Nome Fantasia"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="group.cnpj"
                        dense
                        outlined
                        label="CNPJ"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="group.ie"
                        dense
                        outlined
                        label="Inscrição Estadual"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="group.address"
                        dense
                        outlined
                        label="Endereço"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="group.number"
                        dense
                        outlined
                        label="Número"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="group.complement"
                        dense
                        outlined
                        label="Complemento"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="group.zip_code"
                        dense
                        outlined
                        label="CEP"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="group.district"
                        dense
                        outlined
                        label="Bairro"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="group.city"
                        dense
                        outlined
                        label="Cidade"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="group.state"
                        dense
                        outlined
                        label="Estado"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="group.telephone"
                        dense
                        outlined
                        label="Telefone"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="group.fax"
                        dense
                        outlined
                        label="FAX"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      lg="6"
                    >
                      <v-text-field
                        v-model="group.whatsapp"
                        dense
                        outlined
                        label="Whatsapp"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      lg="6"
                    >
                      <v-text-field
                        v-model="group.email"
                        dense
                        outlined
                        label="Email"
                        variant="underlined"
                      />
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-col
                      v-if="edit"
                      cols="auto"
                    >
                      <v-btn
                        color="success"
                        prepend-icon="mdi-content-save"
                        @click="saveChanges"
                      >
                        salvar
                      </v-btn>
                    </v-col>
                    <v-col
                      v-else
                      cols="auto"
                    >
                      <v-btn
                        color="info"
                        prepend-icon="mdi-plus"
                        @click="createGroup"
                      >
                        cadastrar
                      </v-btn>
                    </v-col>
                  </v-row>
                </form>
              </v-card-text>
            </v-card>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import { mdiDelete, mdiPencil, mdiPlaylistPlus } from '@mdi/js'

export default {
  mixins: [formatters],

  setup() {
    return {
      icons: {
        mdiPlaylistPlus,
        mdiPencil,
        mdiDelete,
      },
    }
  },

  data() {
    return {
      modal: false,
      edit: false,
      groupId: '',
      loadTable: false,
      headers: [
        { text: 'GRUPO', value: 'corporate_name' },
        { text: 'CNPJ', value: 'cnpj' },
        { text: 'EMAIL', value: 'email' },
        { text: 'TELEFONE', value: 'telephone' },
        { text: 'EDITAR', value: 'edit', align: 'center' },
        { text: 'DELETAR', value: 'delete', align: 'center' },
      ],
      items: [],
      infoBase: {},
      group: {},
    }
  },

  created() {
    this.getGroups()
  },

  methods: {
    clearModal() {
      this.edit = false
      this.group = {
        cnpj: '',
        ie: '',
        corporate_name: '',
        fantasy_name: '',
        address: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
        zip_code: '',
        telephone: '',
        fax: '',
        whatsapp: '',
        email: '',
      }
    },

    closeModal() {
      this.modal = false
      this.getGroups()
    },

    async saveChanges() {
      await axiosIns
        .put(`/api/v1/records/group/update/${this.groupId}`, this.group)
        .then(async () => {
          await this.closeModal()
          await this.$swal({
            icon: 'success',
            title: 'Grupo atualizado!',
            text: 'Dados do grupo atualizados!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(async error => {
          await this.closeModal()
          await this.$swal({
            icon: 'error',
            title: 'Erro ao atualizar dados do grupo!',
            showConfirmButton: false,
            text: error,
          })
        })
    },

    async createGroup() {
      await axiosIns
        .post('/api/v1/records/group/store', this.group)
        .then(async () => {
          await this.closeModal()
          await this.$swal({
            icon: 'success',
            title: 'Grupo cadastrado!',
            text: 'Novo grupo cadastrado!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(async error => {
          await this.closeModal()
          await this.$swal({
            icon: 'error',
            title: 'Erro ao cadastrar grupo!',
            showConfirmButton: false,
            text: error,
          })
        })
    },

    async getGroupInfo(item) {
      this.modal = true
      this.groupId = item.id
      this.edit = true
      await axiosIns.get(`/api/v1/records/group/edit/${item.id}`).then(res => {
        this.infoBase = res.data.data
        this.group = res.data.data
      })
    },

    async deleteGroup(item) {
      await this.$swal({
        icon: 'warning',
        title: 'Deseja excluir o Grupo?',
        text: 'A operação é irreversível, tem certeza?',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Não, cancelar!',
      })
        .then(async result => {
          if (result.isConfirmed) {
            await axiosIns.delete(`/api/v1/records/group/destroy/${item.id}`).then(async () => {
              await this.$swal({
                icon: 'success',
                title: 'Grupo excluído!',
                text: 'O grupo foi excluído!',
                showConfirmButton: false,
                timer: 2000,
              })
            })
          } else {
            await this.$swal({
              icon: 'error',
              title: 'Cancelado!',
              text: 'Operação cancelada',
              showConfirmButton: false,
              timer: 2000,
            })
          }
        })
        .catch(async error => {
          await this.$swal({
            icon: 'error',
            title: 'Erro ao excluir grupo!',
            text: error,
          })
        })
    },
    async getGroups() {
      this.loadTable = true
      await axiosIns.get('/api/v1/records/group/index').then(res => {
        this.items = res.data.data
      })
      this.loadTable = false
    },
  },
}
</script>
